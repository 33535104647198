.adminAddNewEducator {
    .inputfieldCenter {
        display: flex;
        justify-content: center;
    }
    .inputFieldContainer {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }
    .inputFieldMultiContainer {
        padding-top: 12px !important;
    }
    .signUpSubmit {
        margin-top: 25px;
    }
}