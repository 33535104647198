.termsContainer{
    padding: auto;
    // padding-top: 2%;
    padding-bottom: 2%;
    background-color: white;
    .termsOptions{
        padding-top: 5%;
        margin-left: 4%;
        background-color: #008dd2;
        font-size: 20px;
        color: white;
    }
    .termsOptions1{
        padding-top: 5%;
        font-size: 20px;
    }
    .termsOptionContainer{
        padding-top: 6%;
        position:relative;
        align-self: center;
    }
    .termsOptionContainer::after{
        top:0;
        position:absolute;
        content: "";
        background-color:rgb(146, 130, 130);
        box-shadow: white;
        height: 300%;
        width: 2%;
        top:9%;
        // bottom: 5%;
        left:100%;
    }
}
.termsAndConditionContent1{
    padding-left: 2%;
    padding-top: 2%;
    font-weight: 600;
    font-size: 25px;
    text-transform: uppercase;
}
.termsAndConditionContent2{
    padding-left: 2%;
    padding-top: 2%;
    // font-weight: 600;
}