

.mentorDashboardCard{
    height: 200px;
}
.mentorDashboardCardContent1{

}
.mentorDashboardCardContent2{
    height: 200px;
    place-items: right;
    clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
    // background-color: #003399;
    background-color: $theme-blue;
    color: white;
}
.cardContentText2{
    padding-top: 30%;
    padding-left: 10%;
    font-size: 12px;
}
.cardContentText1{
    font-size: 20px;
    font-weight: 600;
}