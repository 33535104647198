// .container{
//     position: relative;
    // .carouselImage {
    //     width: 100%;
    //     height: 550px;
    // }
//     .text-block {
//         position: absolute;
//         bottom: 20px;
//         right: 20px;
//         // background-color: black;
//         color: white;
//         padding-left: 20px;
//         padding-right: 20px;
//     }
// }
/* Container holding the image and the text */
.container {
    position: relative;
    text-align: left;
    color: white;
}

.carouselImage {
    width: 100%;
    height: 550px;
    @media only screen and (max-width: 600px) {
      height: 350px; 
    }
}
  
 //----------------Title and Discription----------------------
  .top-left {
    position: absolute;
    top: 25%;
    left: 10%;
    right:60%;
    .carouselHeading{
        font-size: 40px;
        @media only screen and (max-width: 600px) {
          font-size: 16px;
        }
    }
    .carouselDiscription{
        font-size:18px;
        @media only screen and (max-width: 600px) {
          font-size: 10px;
        }
    }
  }
  

  //-------------------- 1st card-------------------
  .cardTitle1{
    position: absolute;
    top: 12%;
    right: 8%;
    left: 55%;
    .cardHeading{
      font-size: 25px;
      @media only screen and (max-width: 600px) {
        font-size: 9px;
      }
    }
  }
  .cardDiscription1{
    position: absolute;
    top: 28%;
    right: 8%;
    left: 54%;
    color: #0048CD;
    text-transform: uppercase;
    .cardDiscription{
      font-size: 14px;
      @media only screen and (max-width: 600px) {
        font-size: 5px;
      }
    }
  }

  //-------------------2nd card---------------------
  .cardTitle2{
    position: absolute;
    top: 40%;
    right: 18%;
    left: 55%;
    .cardHeading{
      font-size: 25px;
      @media only screen and (max-width: 600px) {
        font-size: 9px;
      }
    }
  }
  .cardDiscription2{
    position: absolute;
    top: 56%;
    right: 8%;
    left: 54%;
    color: #0048CD;
    text-transform: uppercase;
    .cardDiscription{
      font-size: 14px;
      @media only screen and (max-width: 600px) {
        font-size: 5px;
      }
    }
  }

  //-------------------3nd card---------------------
  .cardTitle3{
    position: absolute;
    top: 67%;
    right: 20%;
    left: 55%;
    .cardHeading{
      font-size: 25px;
      @media only screen and (max-width: 600px) {
        font-size: 9px;
      }
    }
  }
  .cardDiscription3{
    position: absolute;
    top: 83%;
    right: 8%;
    left: 54%;
    color: #0048CD;
    text-transform: uppercase;
    .cardDiscription{
      font-size: 14px;
      @media only screen and (max-width: 600px) {
        font-size: 5px;
      }
    }
  }

  