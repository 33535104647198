#PersonalityAssessmentGraph {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 400px;
    .grid {
        color:rgb(223, 183, 132);
        // color: #b8c7e4;
        stroke-width: 0.2;
    }
    // .tick {
    //     font-size: 12px;
    //     font-weight: 600;
    // }
}