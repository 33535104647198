.mappingGraphContainer {
  .mappingGraphDropContainer {
    display: flex;
    justify-content: space-between;
  }
  #mappingGraph {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 600px;
    text {
      fill: #fff;
      font-family: sans-serif;
      font-size: 10px;
    }
    .links line {
      stroke: #999;
      stroke-opacity: 0.6;
    }
    .nodes circle {
      stroke: #fff;
      stroke-width: 1.5px;
    }
    .svg-container {
      display: inline-block;
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      vertical-align: top;
      overflow: hidden;
      background: rgb(212, 211, 112);
      border: 1px solid black;
    }
    .svg-content {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
    .legend_text {
      font-size: 20px;
    }
    .legend_text {
      font-size: 16px;
    }
  }
}


// styles for jobTypes


.jobTypes{
  background: #00112F;
  padding: 20px;
  border-radius: 10px;
}

.jobTypes h2, h3{
  text-align: center;
  color: #fff;
}

.jobTypes .scroll {
  opacity: 0;
}

.jobTypes-Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
 
}

.jobTypes-Container div {
  flex: 1 0 300px; 
  margin: 10px;
  border: 5px solid #0048cd;
  border-radius: 5px;

  &:hover {
    border: 5px solid #CD8500;
    cursor: pointer;
  }
  &:hover h3{
    background: #CD8500; 
    cursor: pointer;
  }

}


.jobTypes-Container h3{
  background: #0048cd;
  color: #fff;
  padding: 10px;
  text-align: center;

  

}

.jobTypes-Container p{
  background: #f8f9f9;
  padding: 20px;
  border-radius: 2px;
  height: 140px;
  box-sizing: border-box;
}

// jobPosts
.jobPosts{
  margin-top: 30px;

  h2{
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: center;
  }
  table {
    width: 100%;
    border: 10px solid #00112F;
    border-radius: 5px;
    background-color: #f5f5f5;
    padding: 0;
  }
  
  th,
  td {
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    text-align: center;
    color: #eee;
    background-color: #0048CD;
  }
  
  a {
    color: #fff;
    text-decoration: none;
  }
  
  a:hover {
    color: #000;
  }
  .company{
    background-color: #00AECD;
    &:hover{
      background-color: #01D9FF;
    }
  }


  p{
    margin: 0;
  }

}

.marketSkills{
  background: #00112F;
  padding: 25px;
  color: #fff;
  border-radius: 15px;
  margin: 25px;
}

.marketSkills p{
  font-size: 20px;
}

.job-profile-types{
  margin: 20px;
}

.job-profiles-container{
  display: flex;
  justify-content: space-between;
}

.job-profile-type{
  margin: 40px;
  padding: 20px;
}

.job-posts-table {
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  border: 1px solid black;
  justify-content: space-between;
  height: 100%;
}
