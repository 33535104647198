@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  font-weight: 400;
  background-color: #2c3e50;
}

.wrapper {
  max-width: 850px;
  width: 100%;
  margin: 50px auto;
}

.question {
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 2em;
  &.error {
    background-color: #e74c3c;
  }
  &.success {
    background-color: #2ecc71;
  }
  .question-headline {
    font-weight: 700;
    border-bottom: 2px solid #ddd;
    // font-size: 1.5em;
    font-size: 1.8em;

    color: #000;
  }
  // .question-answers {
  //   margin-top: 1em;
  //   color: #222;
  //   .answer {
  //     & + .answer {
  //       margin-top: 0.5em;
  //     }
  //     input {
  //       display: inline-block;
  //       margin-right: 1em;
  //     }
  //     label {
  //       cursor: pointer;
  //     }
  //   }
  // }
  .question-answers {
    margin-top: 1em;
    color: #222;
    display: flex; /* Use flexbox for side-by-side layout */
    gap: 1em; /* Space between the options */
    flex-wrap: wrap; /* Allow wrapping if options exceed the width */
  }

  .answer {
    display: flex; /* Align the radio button and label side by side */
    align-items: center; /* Align items vertically */
    font-size: 18px;
  }

  input[type="radio"] {
    margin-right: 0.5em; /* Space between the radio button and text */
  }

  label {
    cursor: pointer;
  }
}

.result {
  margin-bottom: 2em;
  font-size: 1.2em;
  background-color: #3498db;
  border-radius: 10px;
  padding: 10px 20px;
  display: none;
}

.btn-check {
  padding: 10px 20px;
  background-color: #2ecc71;
  color: #fff;
  border-radius: 10px;
  border: 0;
  cursor: pointer;
  font-size: 1.5em;
  &:hover {
    background-color: darken(#2ecc71, 20%);
  }
}
