// .myProgramsContainer{
//     margin-top: 20%;
//     padding-top: 2%;
// }
.programCard1 {
  padding: 0%;
  margin-top: 8%;
  width: 90%;
  height: 320px;
  border-radius: 50%;

  // box-shadow: violet;
}
.programName {
  padding: 2%;
  color: #003399;
}
.cardImgContainer {
  height: auto;
  width: 80%;
}
.programCardImg1 {
  height: 120px;
  width: 100%;
  padding-bottom: 5px;
}
.prgInfoContainer {
  // background-color: #003399;
  background-color: $theme-blue;
  padding: 4%;
  height: 170px;
}
.prgInfo1 {
  color: white;
  font-size: 14px;
}
.prgInfo2 {
  // height: 70px;
  color: #82c4e4;
  font-size: 12px;
}
.progressBar {
  margin-top: 5%;
  margin: 2%;
}

.programCard1 {
  margin-top: 35px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}

.programCard1:hover {
  margin-top: 35px;
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////// LearnerCourse.js ////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////@at-root

.TestColumnHeading {
}
.tabTitle {
  font-size: 1%;
}
.progressBarValue {
  padding-left: 60%;
  color: #048fd3;
}
