.mainViewContainer {
  .mainViewHeading {
    // background: #003399;
    background: $theme-blue;
    margin-bottom: 10px;
    .headingTagLine {
      padding: 20px 0;
      font-size: 18px;
      flex-wrap: wrap;
      text-align: center;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 4px;
      color: $white-color;
    }
  }
}

