.reportGeneralText {
    color: #1f4697;
    padding-left: 4%;
}
.timePaper{
    height: 100px;
    padding: 2%;
}
.timeText{
    padding-top: 4%;
    padding-left: 8%;
    color: #7b7a78;
    font-size: 30px;
}