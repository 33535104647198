@import "./sass/index";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
}

* {
  font-family: "Lato", sans-serif !important;
  margin: 0;
  padding: 0;
}

* {
  font-family: "Inter", sans-serif !important;
  margin: 0;
  padding: 0;
}


body > #zmmtg-root {
  display: none;
}

#zmmtg-root,
.meeting-client,
.meeting-client-inner {
  width: 100% !important;
  height: 98% !important;
  position: relative !important;
}

#wc-container-right {
  height: 99vh !important;
  padding-top: 3vh !important;
}
// ::-webkit-scrollbar {
//   width: 5px;
// }

// ::-webkit-scrollbar-corner {
//   background: rgba(0,0,0,0.5);
// }

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 2px #e4e4e4e4;
//   border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   -webkit-box-shadow: inset 0 0 5px #e4e4e4e4;
// }

// ::-webkit-scrollbar:horizontal {
//   height: 5px;
//   cursor: pointer;
//   -webkit-box-shadow: inset 0 0 2px #e4e4e4e4;
//   border-radius: 10px;
// }
