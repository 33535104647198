.prgDetailsCard {
  // margin-left: 40px;
  .programDetails {
    display: flex;
    justify-content: center;
    .prgDetails {
      // background: #003399;
      background:$theme-blue;
      padding-left: 80px;
      padding-right: 80px;
      .headingTagLine {
        padding: 10px 0;
        font-size: 18px;
        flex-wrap: wrap;
        text-align: center;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 4px;
        color: $white-color;
      }
    }
  }

  .prgPathList {
    padding-left: 10px;
    font-size: 20px;
    font-weight: 400;
  }
  // .prgDetails{
  //     background: #008dd2;
  //     .headingTagLine {
  //         // padding: 10px 0;
  //         font-size: 18px;
  //         flex-wrap: wrap;
  //         text-align: center;
  //         font-weight: 400;
  //         text-transform: uppercase;
  //         letter-spacing: 4px;
  //         color: $white-color;
  //     }
  // }
  img {
    width: 100%;
  }
  // .imgDataEng{
  // padding-right: 50%;
  // background-color: black;
  // }
  .noHrs {
    font-size: small;
    color: gray;
  }
}

.prgPathList {
  padding-left: 10px;
  font-size: 16px;
}
.programDetails {
  display: flex;
  justify-content: center;
  .prgDetails {
    // background: #003399;
    background: $theme-blue;
    padding-left: 80px;
    padding-right: 80px;
    .headingTagLine {
      padding: 10px 0;
      font-size: 18px;
      flex-wrap: wrap;
      text-align: center;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 4px;
      color: $white-color;
    }
  }
}
.prgMantorContainer {
  .programDetails {
    // margin-left: 11px;
    display: flex;
    justify-content: left;
    .prgDetails {
      background: #003399;
      padding-left: 80px;
      padding-right: 80px;
      .headingTagLine {
        padding: 10px 0;
        font-size: 18px;
        flex-wrap: wrap;
        text-align: center;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 4px;
        color: $white-color;
      }
    }
  }
  // width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #008dd2;
  .mentorCard {
    // width: 100%;
    margin-left: 10px;
    margin-right: 30px;
    .mentorImg {
      display: flex;
      justify-content: end;
      clip-path: circle();
      height: 120px;
      width: 120px;
      background-color: #008dd2;
    }
    .collabText {
      width: 100%;
      display: inline-block;
      .collabText1 {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  // .collabText{
  //     display: flex;
  //     justify-content:right;

  // }
  // .mentorImg{
  //     display: flex;
  //     justify-content: end;
  //     clip-path: circle();
  //     height: 120px;
  //     width: 120px;
  //     background-color: #87c6f8;
  // }
}
// .mentorCard{
//     display: flex;
//     justify-content:space-between;
// }
// .mentorImg{
//     display: flex;
//     justify-content: end;
//     clip-path: circle();
//     height: 180px;
//     width: 180px;
//     background-color: #87c6f8;
// }
