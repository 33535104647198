.LableText{
    padding-left :2%;
    padding-top: 2%;
    font-size: 18px;
}
.inputFields{
    margin : 1%;
    // padding-top: 2%;
    width: 20px;
}
.addProgramMainCard{
    margin: 1%;
}
.LableText1{
    padding-left :3%;
    padding-top: 2%;
    font-size: 18px;
}
.inputPhoto{
    margin : 50%;
    padding-top: 2%;
    width: 80%;
}
.pageHeader{
    margin: 1%;
}