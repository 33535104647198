.mainViewContainer {
  margin: 1%;
  .mainViewHeading {
    // background: #003399;
    background: $theme-blue;
    margin-bottom: 10px;
    .headingTagLine {
      // padding: 20px 0;
      font-size: 18px;
      flex-wrap: wrap;
      text-align: center;
      // font-weight: 400;
      // text-transform: uppercase;
      letter-spacing: 4px;
      color: $white-color;
    }
  }
  .headingNumber {
    height: 70px;
    width: 60px;
    background-color: #003399 ;
    border-radius: 0% 50% 50% 0% / 10% 50% 50% 10%;
    color: white;
    padding-top: 8px;
    font-size: 30px;
    font-weight: bolder;
    // font-family: Roboto;
    // text-align: center;
    // align-items: left;
    // filter: drop-shadow(4px 4px #a6a6a6);
    // justify-content: center;
  }
  .headingText {
    // background-color: #87c6f8;
    padding-top: 21px;
    width: 100%;
    font-size: 18px;
    padding-left: 21px;
    font-weight: bolder;
    color: #003399;
    font-weight: bolder;
    font-family: Roboto;
  }
  .cartSummaryImg {
    display: flex;
    justify-content: end;
    clip-path: circle();
    height: 150px;
    width: 150px;
    background-color:#003399;
    margin-top: 25px;
  }
  .cartSummaryContent {
    margin-top: 5px;
  }
  .cartSummaryCost {
    display: flex;
    justify-content: flex-end;
  }
  .cartSummaryCostDetails {
    display: flex;
    // flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .learnerDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .learnerDetailsTextFields {
      width: 50%;
      // margin-left: 45%;
      // margin-right: 45%;
    }
  }
  .containerPadding {
    padding-left: 30px;
  }
  .productHeading {
    font-size: 21px;
    flex-wrap: wrap;
    padding-top: 20px;
    // text-align: center;
    font-weight: bolder;
    // font-weight: 400;
    // text-transform: uppercase;
    letter-spacing: 4px;
    color: #504f4f;
  }

  .productPrize {
    font-size: 19px;
    flex-wrap: wrap;
    padding-top: 20px;
    // text-align: center;
    font-weight: bolder;
    // font-weight: 400;
    // text-transform: uppercase;
    // letter-spacing: 4px;
    color: #504f4f;
  }
  .productDescription {
    font-size: 15px;
    flex-wrap: wrap;
    padding-top: 20px;
    // text-align: center;
    font-weight: normal;
    // font-weight: 400;
    // text-transform: uppercase;
    // letter-spacing: 4px;
    color: #504f4f;
  }
  .gstGrid {
    padding-top: 120px;
  }
  .finalPrice {
    font-size: 19px;
    flex-wrap: wrap;
    padding-top: 20px;
    font-weight: bolder;
    color: #504f4f;
  }
  .cartSummaryButton {
    margin-top: 20px;
    text-align: end;
  }
  .formWidth {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
  }

  .headingNumberClosed {
    height: 70px;
    width: 5%;
    background-color: white;
    border-radius: 0% 50% 50% 0% / 10% 50% 50% 10%;
    color: #003399;
    padding-top: 8px;
    font-size: 30px;
    font-weight: bolder;
    overflow: hidden;
    // font-family: Roboto;
    // text-align: center;
    // align-items: left;
    // filter: drop-shadow(4px 4px #a6a6a6);
    // justify-content: center;
  }
  .headingTextClosed {
    background-color: #003399;
    width: 100%;
    border-radius: 4% 10% 10% 4% / 50% 0% 0% 50%;
    padding-top: 21px;
    padding-left: 21px;
    font-size: 18px;
    font-weight: bolder;
    color: white;
    font-weight: bolder;
    font-family: Roboto;
  }
  .changeButton {
    margin: 15px;
    width: 250px;
    font-size: larger;
    color: #504f4f;
  }
  .grandTotal {
    margin-right: 20px;
    padding-top: 7px;
    font-size: larger;
    color: #504f4f;
  }
  .proceedButton {
    background-color: #ff8300;
    width: 200px;
    font-weight: 600;
    text-transform: "capitalize";
    letter-spacing: 1;
    &:hover {
      background-color: #008dd2 !important;
    }
  }
}
