.dashboardContainer {
  margin-top: 1%;
  .mainViewHeading {
    // background: #003399;
    background: $theme-blue;
    margin-bottom: 10px;
    .headingText {
      padding: 20px 0;
      font-size: 18px;
      flex-wrap: wrap;
      text-align: center;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 4px;
      color: $white-color;
    }
  }
  .inputFieldContainer {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .dateTimePickerContainer {
    padding-top: 12px !important;
    .dateTimePicker {
      width: 100%;
      margin: 0;
    }
  }
  .videoViewContainer {
    display: flex;
    justify-content: center;
    .videoView {
      position: relative;
      width: 100%;
      // height: 450px;
      .buttonContainer {
        button {
          border: 1px solid #b0b0b0;
          border-radius: 4px;
          background: #003399;
          color: white;
          margin: 0 5px 10px 0;
          padding: 3px;
          width: 99px;
        }
        button:last-of-type {
          // margin: 0;
        }
        button:disabled,
        button[disabled] {
          border: 1px solid #999999;
          background-color: #40484d76;
          color: #ffffff;
        }
      }

      video {
        // position: absolute;
        margin: 5px;
        // vertical-align: top;
        --width: 35vw;
        width: var(--width);
        height: calc(var(--width) * 0.5625);
      }

      video:last-of-type {
        margin: 0 0 20px 0;
      }

      video#gumVideo {
        margin: 0 20px 20px 0;
      }
    }
  }
  .videoContent {
    ol {
      padding: 20px;
    }
  }
  .submitButton {
    margin: 25px 0;
    margin-bottom: 0;
    .formSubmit {
      width: 100%;
    }
  }
  .colorSpace1 {
    display: flex;
    justify-content: center;
    margin-top: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
    background-color: rgb(199, 11, 11);
    // transform: skew(0deg, -10deg);
    .iconCard {
      display: grid;
      place-items: center;
      border-radius: 50%;
      filter: drop-shadow(1px 4px rgb(199, 11, 11));
      height: 40px;
      width: 40px;
      background-color: rgb(199, 11, 11);
    }
  }
  .colorSpace2 {
    display: grid;
    place-items: center;
    background-color: #003399;
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
    .iconCard {
      display: grid;
      place-items: center;
      border-radius: 50%;
      filter: drop-shadow(1px 2px #003399);
      height: 40px;
      width: 40px;
      background-color: #003399;
    }
  }
  .Icon {
    display: grid;
    place-items: center;
    color: white;
  }
}

.dashboardCard{
  // position: absolute;
  // transform: translate(-50%, -50%);
  height: 80px;
  // width: 00px;
  // background-color: white;
  // box-shadow: 0 10px 30px rgba(0,0,0,0.5);
  // display: flex;
}
// .dashboardCardContent1{
  // width : calc(50% - 50px);
  // width : 100%;
  // height: 100%;
  // background-color: white;
  // padding: 2% 5%;
  // line-height: 1.5em;
  // text-align: justify;
// }

.dashboardCardContent2{
  height: 80px;

    // display: flex;
    // justify-content: center;
    // margin-top: 0;
    // padding: 0;
    // height: 100%;
    // width: 20%;
    place-items: right;
    clip-path: polygon(70% 1%, 100% 0, 100% 100%, 2% 100%);
    // background-color: rgb(199, 11, 11);
    // background-color: #003399;
    background-color: $theme-blue;
}

.dashboardCard{
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
}

.dashboardCard:hover{
  // margin-top: 35px;
  transform: scale(1.02);
  // color: #FFE481;
  // box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}