.PersonalityAssessmentContainer {
    .headingText {
        padding: 10px 0;
        font-size: 18px;
        font-weight: 600;
        color: #666666;
        text-align: center;
    }
    .headingTagLine {
        padding: 10px 0;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
    }
    .PersonalityAssessmentTest {
        width: 100%;
        height: 200px;
        overflow: auto;
        padding: 3% 0;
        padding-right: 6%;
        box-sizing: content-box;
        .assessmentQuestion {
            padding: 10px 0;
            font-size: 18px;
            font-weight: 600;
            color: #666666;
            text-align: justify;
            text-justify: inter-word;
        }
        .assessmentAnswer {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 2px 0;
            .assessmentCheck {
                padding: 0;
                margin-right: 10px;
            }
            .assessmentAnswer {
                text-align: left;
                font-size: 14px;
                font-weight: 600;
                color: #666666;
            }
        }
    }
    .submitContainer {
        display: flex;
        justify-content: center;
    }
}