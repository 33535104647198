// .welcomeContainer {
//     .headingText {
//         padding: 10px 0;
//         font-size: 18px;
//         font-weight: 600;
//         // color: #666666;
//     }
//     .headingTagLine {
//         padding: 10px 0;
//         font-size: 18px;
//         flex-wrap: wrap;
//         font-weight: 400;
//     }
//     .welcomBrandContainer, .welcomBrandContentContainer {
//         background: #008dd2;
//         display: flex;
//         justify-content: center;
//         height: 550px;
//         align-items: center;
//         color: $white-color;
//         .welcomeHeading {
//             border: 5px solid #e4e4e4;
//             .headingText{
//                 font-size: 70px;
//                 font-weight: 500;
//                 letter-spacing: 1px;
//                 padding: 15px 150px;
//                 border-bottom: 5px solid #e4e4e4;

//                 @media only screen and (max-width: 600px) {
//                     font-size: 30px;
//                     padding: 10px 75px;
//                 }
//             }
//             .headingTagLine {
//                 text-align: center;
//                 text-transform: uppercase;
//                 padding: 20px 0;
//                 letter-spacing: 4px;

//                 @media only screen and (max-width: 600px) {
//                     font-size: 8px;
//                 }
//             }
//         }
//     }
//     .welcomBrandContentContainer {
//         margin-top: 50px;

//         @media only screen and (max-width: 600px) {
//             margin-top: 20px;
//             .headingText {
//                 font-size: 22px !important;
//             }
//         }
//     }
//     .welcomeContentContainer {
//         margin-top: 50px;

//         @media only screen and (max-width: 600px) {
//             margin-top: 20px;
//         }
//         .welcomeContentTextContainer {
//             display: flex;
//             height: 400px;
//             align-items: center;
//             padding: 40px;

//             @media only screen and (max-width: 600px) {
//                 height: 300px;
//                 padding: 20px;
//             }
//             .headingText {
//                 font-size: 30px;
//                 color: #008dd2;

//                 @media only screen and (max-width: 600px) {
//                     font-size: 24px;
//                 }
//             }
//             .headingTagLine {
//                 font-size: 28px;
//                 font-weight: 400;
//                 color: #8c8c8c;

//                 @media only screen and (max-width: 600px) {
//                     font-size: 22px;
//                 }
//             }
//         }
//     }
//     .welcomeImages {
//         width: 100%;
//         height:400px;
//     }
// }

// --------------------------------------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------------------------------

.welcomeContainer {
  .headingText {
    padding: 10px 0;
    font-size: 19px;
    font-weight: 600;
    // color: #666666;
  }
  .headingTagLine {
    padding: 10px 0;
    font-size: 16px;
    flex-wrap: wrap;
    font-weight: 400;
  }
  .welcomBrandContainer {
    padding: 20%;
    background: linear-gradient(180deg, #008dd2 51%, #004f92 49%);
    display: flex;
    justify-content: center;
    height: 725px;
    align-items: center;
    color: $white-color;
    @media only screen and (max-width: 600px) {
      width: 100%;
      height: 250px;
      // padding: 10%;
    }
    .landingVideoContainer {
      display: flex;
      justify-content: center;
      // width:100%;
      // height: 100%;
      // height:10px;
      // @media only screen and (max-width: 600px) {
      //   // width: 550px;
      //   // height: 250px;
      // }
      .welcomeVideoClass {
        // width:100%;
        // height: 100%;
          // height:100px;

        // @media only screen and (max-width: 430px) {
        //   width: 380px;
        //   height: 40px;
        // }
      }
      // padding:20%
    }
    .welcomeHeading {
      border: 5px solid #e4e4e4;
      .headingText {
        text-align: center;
        font-size: 70px;
        font-weight: 500;
        letter-spacing: 1px;
        // padding: 15px 150px;
        // padding: 10px 80px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 80px;
        padding-right: 80px;
        border-bottom: 5px solid #e4e4e4;

        @media only screen and (max-width: 430px) {
          font-size: 30px;
          padding: 0;
        }
        
      }

      .headingText1 {
        text-align: center;
        font-size: 70px;
        font-weight: 500;
        letter-spacing: 1px;
        // padding: 15px 150px;
        // padding: 10px 80px;
        padding-top: 0px;
        padding-bottom:0px;
        padding-left: 80px;
        padding-right: 80px;
        border-bottom: 5px solid #e4e4e4;

        @media only screen and (max-width: 430px) {
          font-size: 30px;
          padding: 0;
        }
        
      }


      .headingTagLine {
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
        padding: 18px 0;
        letter-spacing: 4px;

        @media only screen and (max-width: 600px) {
          font-size: 8px;
          padding: 0% 0;
        }
      }
      .headingTagLine1 {
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
        padding: 30px 0;
        letter-spacing: 4px;

        @media only screen and (max-width: 600px) {
          font-size: 8px;
          padding: 0% 0;
        }
      }
    }
  }
  // .contentGrid{
  //     display: flex;
  //     justify-content: center;
  // }
  .contentGrid {
    padding-top: 100px;
  }
  .landingTextContainer {
    padding-top: 20px;
    width: 100%;
  }
  .landingImageContainer {
    // width: 100%;
    @media only screen and (max-width: 600px) {
      width: 444px;
      // height: 400px;
    }
  }
  .contentHeadingText {
    color: #008dd2;
    font-size: 20px;
    // padding-left: 10px;
  }
  .contentTagLine {
    font-size: 18px;
    padding-top: 10px;
    // padding-left: 10px;
    // padding-right: 0px;
  }
  .contentTagLine1 {
    font-size: 20px;
    padding-top: 15%;
    // padding-left: 10px;
    // padding-right: 10px;
  }
  .rightImg {
    display: flex;
    // align-items: right;
    justify-content: flex-end;
    @media only screen and (max-width: 430px) {
      display: flex;
      justify-content: flex-start;
    }
  }
  // img, video{
  //     @media only screen and (max-width: 600px) {
  //     width: 400px;
  //     }
  // }
  .mediaClassImg {
    @media only screen and (max-width: 600px) {
      padding-top: 20%;
      padding-bottom: 10%;
      height: 100%;
      width: 100%;
    }
  }
  .landingVideoContainer {
    display: flex;
    justify-content: center;
    // width:100%;
    // height: 100%;
    .welcomeVideoClass {
      // width:100%;
      // height: 100%;

      @media (max-width: 600px) {
        width: 380px;
        height: 600px;
      }
    }
    // padding:20%
  }
  .tremsConditions{
    padding:3%;
    background-color: #008dd2;
    color: white;
    font-size: 18px;
    margin-bottom: 3%;
  }
  .tremsConditionsSubContent{
    padding-top: 3%;
  }
  .tremsConditionsLink{
    text-decoration: none;
    color: white;
  }
}
