.SignInContainer {
  .mainViewHeading {
    // background: #003399;
    background: $theme-blue;
    margin-bottom: 10px;
    .headingLine {
      padding: 20px 0;
      font-size: 18px;
      flex-wrap: wrap;
      text-align: center;
      font-weight: 400;
      letter-spacing: 4px;
      color: $white-color;
    }
  }
  .headingText {
    padding: 10px 0;
    font-size: 18px;
    font-weight: 600;
    color: #666666;
  }
  .headingTagLine::before {
    content: "*";
    position: relative;
    top: 10px;
    margin-right: 10px;
    font-size: 30px;
  }
  .headingTagLine {
    padding: 10px 0;
    font-size: 18px;
    flex-wrap: wrap;
    text-align: justify;
    font-weight: 400;
  }
  .tryItContainer {
    text-align: center;
    margin-bottom: 20px;
  }
}

.SignIn_SignUp_Container {
  .innerContainer {
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    .cardContent {
      padding-bottom: 16px;
      .or {
        text-align: center;
        font-size: 12px;
        color: rgba(95, 106, 106, 1);
      }
      .socialIconsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .headingText {
      font-weight: 600;
      text-align: center;
    }
    .submitButton {
      margin: 10px 0;
      margin-bottom: 10px;
      // color: #fff;
      // background-color: #008dd2;
      font-weight: 600;
      // text-transform: capitalize;
      text-transform: capitalize;
      width: 100%;
      text-transform: capitalize;
      letter-spacing: 1;
    }
    .formSubmit {
      width: 100%;
      color: #fff;
      // background-color: #003399;
      background-color: $theme-blue;
      hover: {
        background-color: "#003399";
      }
    }
    .forgotPasswordContainer,
    .alreadyAccountContainer {
      display: flex;
      justify-content: center;
      margin: 10px;
      .forgotPassword,
      .alreadyAccount {
        padding: 0 5px;
        color: "#003399";
        cursor: pointer;
        text-decoration: none;
      }
      .alreadyAccount {
        font-weight: 600;
      }
    }
    .inputFieldContainer {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
    .signUpSubmit {
      margin-top: 25px;
    }
  }
}

.SignInContainer {
}

.facebook-social {
  cursor: pointer;
  margin-right: 8px;
}

.google-social {
  cursor: pointer;
  margin: 0 8px;
}


.learnerEditProfileContainer{
  // padding:"30%"
}
.editFormSubmit{
  width: 80%;
  padding-top: 20%;
}