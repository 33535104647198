.mainViewContainer {
  margin: 1%;
  .mainViewHeading {
    // background: #003399;
    background: $theme-blue;
    margin-bottom: 10px;
    .headingTagLine {
      // padding: 20px 0;
      font-size: 18px;
      flex-wrap: wrap;
      text-align: center;
      // font-weight: 400;
      // text-transform: uppercase;
      letter-spacing: 4px;
      color: $white-color;
    }
  }
  .optionCard {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  }

  .optionCard:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }
  .imgCard {
    display: flex;
    justify-content: space-between;
    // background: url(../../images/programs/backgroundQACard.png) no-repeat;
    // background-size: cover;
    // background-blend-mode: lighten;
    // opacity: 0.25;

    // background-color:#87c6f8;
  }
  .imagCard1 {
    background-image: url(../../images/programs/backgroundDECard.png);
    background-size: cover;
  }
  .imagCard2 {
    background-image: url(../../images/programs/backgroundQACard.png);
    background-size: cover;
  }
  .imagCard3 {
    background-image: url(../../images/programs/backgroundBACard.png);
    background-size: cover;
  }

  // .imgCard1:hover{
  //     position: relative;
  //     top: -7px;
  //     // right: -7px;
  // }

  .cardText {
    // display: flex;
    // justify-content: center;
    // background-color: #87c6f8;
    padding-top: 45px;
    text-align: center;
    font-size: 18px;
    color: white;
    text-decoration: opacity($color: 1);

    // position: relative;
  }
  .cardImg {
    display: flex;
    justify-content: end;
    // clip-path: circle();
    // border-radius: 50%;
    // height: 180px;
    // width: 180px;
    .imgInfo {
      border-radius: 50%;
      height: 180px;
      width: 180px;
      padding: 2px;
      border: 4px solid white;
      opacity: 1;
    }
  }
  .noHrs {
    font-size: small;
    color: gray;
  }
  .test {
    width: 100%;
    // background-color:#008dd2 ;
  }
  // .prgDetailsCard{
  //     margin-left: 40px;
  //     .programDetails{
  //         display: flex;
  //         justify-content: center;
  //         .prgDetails{
  //             background: #008dd2;
  //             padding-left: 42px;
  //             padding-right: 130px;
  //             .headingTagLine {
  //                 padding: 10px 0;
  //                 font-size: 18px;
  //                 flex-wrap: wrap;
  //                 text-align: center;
  //                 font-weight: 400;
  //                 text-transform: uppercase;
  //                 letter-spacing: 4px;
  //                 color: $white-color;
  //             }
  //             .imgDataEng{
  //                 padding-right: 20%;
  //                 background-color: black;
  //             }
  //         }
  //     }
  // }

  // .imgInfo{
  //     clip-path: circle();
  //     height: 150px;
  //     width: 150px;
  //     background-color: #b1dcff;
  // }
  .prgIncludes {
    margin: 15px;
    .programDetails {
      display: flex;
      justify-content: center;
      .prgDetails {
        // background: #003399;
        background: $theme-blue;
        padding-left: 80px;
        padding-right: 80px;
        .headingTagLine {
          padding: 10px 0;
          font-size: 18px;
          flex-wrap: wrap;
          text-align: center;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 4px;
          color: $white-color;
        }
      }
    }
  }

  .imgInclude {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    object-fit: "cover";
  }

  .imgInclude:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }

  .underLine {
    margin-top: 0%;
    width: 120px;
    height: 4px;
    background-color: #008dd2;
    display: inline-block;
    // border-radius: 50%;
  }

  // .imgInclude{
  //     // margin-left: 20px;
  // }

  .articalCard {
    height: 80%;
    border-radius: 5%;
    margin: 2%;
    object-fit: cover;
  }
  .articalCard1 {
    border-radius: 5%;
    box-shadow: black;
  }
  .articalCard {
    // margin-top: 35px;
    // box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  }

  .articalCard:hover {
    // margin-top: 35px;
    transform: scale(1.05);
    // color: #FFE481;
    // box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  }
}
