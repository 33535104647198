// .aboutUsContainer {
//     .aboutUsContentContainer {
//         background: rgba(233, 250, 250, 1);
//         height: auto;
//         .aboutInnerContent {
//             padding: 20px;
//             .headingText, .subHeadingText {
//                 font-size: 30px;
//                 font-weight: 700;
//                 // text-align: justify;
//             }
//             .aboutList {
//                 list-style-type: none;
//                 .headingTagLine::before {
//                     content: '*';
//                     position: relative;
//                     top: 10px;
//                     margin-right: 15px;
//                     font-size: 30px;
//                 }
//                 .headingTagLine {
//                     font-size: 25px;
//                     padding: 15px 0;
//                     font-weight: 400;
//                     // text-align: justify;
//                 }
//             }
//             .subHeadingText {
//                 padding: 15px 0;
//             }
//         }
//         .aboutInfo {
//             display: flex;
//             .infoImage {
//                 width: 150px;
//                 height: 180px;
//                 background: #66bae4;
//                 border-radius: 2px;
//             }
//             .infoUser {
//                 padding: 15px 25px;
//                 .headingText {
//                     font-size: 30px;
//                     font-weight: 700;
//                 }
//                 .subHeadingText {
//                     font-size: 22px;
//                     font-weight: 600;
//                 }
//             }
//         }
//         .subText {
//             font-size: 22px;
//             padding: 10px;
//             font-weight: 400;
//             // text-align: justify;
//         }
//     }
//     .info {
//         margin-bottom: 20px;
//     }
// }

//---------------------NEW ABOUT US0.1 ----------------------------------------------------//

// .mainContainer{
//      margin: 1%;
//     .headingText{
//         background-color: rgba(33,150,243,255);
//         text-align: center;
//         // font-size: xxx-large;
//         color: white;
//     }
//     .headingText1{
//         text-align: center;
//     }
//     .subHeadingText{
//         text-align: center;
//     }
//     .midGrid{
//         color: #91d0f8;
//         text-align: center;
//     }
//     .textInfo1{

//         border-radius: 0% 0% 70% 0% / 0% 0% 70% 0% ;
//         background-color: #87c6f8;
//         text-align: center;
//         height: 120px;
//         width: 150px;

//     }
//     .textInfo2{

//         border-radius: 0% 0% 0% 70% / 0% 0% 0% 70%  ;
//         background-color: #87c6f8;
//         text-align:center;
//         height: 120px;
//         width: 150px;
//     }

//     .imgInfo{
//         clip-path: circle();
//         height: 150px;
//         width: 150px;
//     }

//     .listPointer{
//         text-align: center;
//         background-color: #87c6f8;
//         clip-path: circle();
//         height: 50px;
//         width: 50px;
//         color: white;
//     }

//     .box{
//         filter: drop-shadow(1px 5px #a6a6a6);
//     }

//     .aboutUsContentContainer{
//         display: flex;
//         justify-content: space-between;
//     }

//     .aboutUsContentContainer1{
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//     }

//     // .aboutUsContentContainer1{
//     //     display: flex;
//     //     justify-content:center;
//     // }

//     .imgClass3{

//         display: flex;
//         justify-content:center;
//         // background-color: #87c6f8;
//         // border-radius:0% 0% 50% 50% / 0% 0% 100% 100%  ;
//         // height: 30px;
//         // width: 150px;
//     }
// }

//-------------------------------------------- NEW ABOUT US 0.2 ---------------------------------------------------------

// .mainViewContainer {
//     margin: 1%;
//     .mainViewHeading {
//         background: #008dd2;
//         margin-bottom: 10px;
//         .headingTagLine {
//             padding: 20px 0;
//             font-size: 18px;
//             flex-wrap: wrap;
//             text-align: center;
//             // font-weight: 400;
//             // text-transform: uppercase;
//             letter-spacing: 4px;
//             color: $white-color;
//         }
//     }
//     .listPointer{
//         text-align: center;
//         background-color: #87c6f8;
//         clip-path: circle();
//         height: 30px;
//         width: 35px;
//         color: white;
//     }
//     .box{
//         filter: drop-shadow(1px 4px #a6a6a6);
//     }
// .imgInfo{
//     clip-path: circle();
//     height: 150px;
//     width: 150px;
// }
//     // .aboutUsContentContainer{
//     //     display: flex;
//     //     justify-content: space-between;
//     // }
//     .aboutUsContentContainer{
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         text-align: center;
//     }
//     .aboutUsContentContainer1{
//         display: flex;
//         flex-direction: column;
//         // justify-content: center;
//         text-align: center;
//     }
//     .textInfo1{

//         border-radius: 0% 0% 70% 0% / 0% 0% 70% 0% ;
//         background-color: #87c6f8;
//         text-align: center;
//         height: 120px;
//         width: 150px;

//     }
//     // .textInfo2{
//     //     display: flex;
//     //     justify-content: end;
//     //     border-radius: 0% 0% 0% 70% / 0% 0% 0% 70%  ;
//     //     background-color: #87c6f8;
//     //     text-align:center;
//     //     height: 120px;
//     //     width: 150px;
//     // }

//     .testDiv{
//         display: flex;
//         justify-content: center;
//         .textInfo{
//             border-radius: 0% 0% 50% 50% / 0% 0% 100% 100% ;
//             background-color: #87c6f8;
//             height: 100px;
//             width: 220px;
//             // height: 100px;
//             // width: 220px;
//         }
//     }
// }

//-------------------------------------------- NEW ABOUT US 0.3 ---------------------------------------------------------

.mainViewContainer {
  margin: 1%;
  .mainViewHeading {
    // background: #003399;
    background: $theme-blue;
    margin-bottom: 10px;
    .headingTagLine {
      padding: 20px 0;
      font-size: 18px;
      flex-wrap: wrap;
      text-align: center;
      // font-weight: 400;
      // text-transform: uppercase;
      letter-spacing: 4px;
      color: $white-color;
    }
  }
  .aboutList {
    list-style-type: none;
    .headingTagLine::before {
      content: "*";
      position: relative;
      top: 10px;
      margin-right: 15px;
      font-size: 30px;
    }
  }

  .imagecontainer {
    top: 15px;
    position: relative;
    text-align: left;
    color: white;
  }

  .staticImage {
    width: 100%;
    height: 550px;
    @media only screen and (max-width: 600px) {
      height: 350px;
    }
  }

  .imageContent {
    position: absolute;
    top: 20%;
    left: 10%;
    right: 50%;
    .imageDiscription {
      color: #326cd7;
      font-size: 18px;
      @media only screen and (max-width: 600px) {
        font-size: 10px;
      }
    }
    @media only screen and (max-width: 600px) {
      top: 5%;
    }
    .imageTagLine {
      color: #326cd7;
      margin-top: 8px;
      font-size: 18px;
      @media only screen and (max-width: 600px) {
        font-size: 10px;
      }
    }
  }

  .testDiv {
    display: flex;
    justify-content: center;
    .textInfo {
      border-radius: 0% 0% 50% 50% / 0% 0% 100% 100%;
      background-color: #0000ff;
      height: 100px;
      width: 220px;
      color: white;
      // height: 100px;
      // width: 220px;
    }
  }
  .imgClass3 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    clip-path: circle();
    height: 150px;
    width: 150px;
  }
  .aboutUsContentContainer1 {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    text-align: center;
  }
  .aboutCard {
    margin: 5%;
  }
  .aboutCard {
    margin-top: 35px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  }

  .aboutCard:hover {
    margin-top: 35px;
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }
  // .imgInfo{
  //     clip-path: circle();
  //     height: 150px;
  //     width: 150px;
  //     background-color: #b1dcff;
  // }
}
